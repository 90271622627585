import React, {useState, useEffect} from "react";
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import {toast, Toaster} from "react-hot-toast";
import ConsignorService, { Tier } from "../consignors.service"


type Props = {
    show: boolean,
    onClose: () => void,
    onConsignorCreated: () => void
}

const CreateConsignorModal: React.FC<Props> = ({show, onClose, onConsignorCreated}) => {
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [tierId, setTierId] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [tiers, setTiers] = useState<Tier[]>([])

    useEffect(() => {
        (async () => {
            setTiers(await ConsignorService.fetchTiers())
        })()
    }, [])

    const handleClose = () => onClose();

    const resetForm = () => {
        setEmail('')
        setPhoneNumber('')
        setFirstName('')
        setLastName('')
        setTierId('')
    }

    const createConsignor = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isSubmitting) return;

        setIsSubmitting(true)
        try {
            const response = await ConsignorService.createConsignor(
                email, phoneNumber, firstName, lastName, tierId
            )

            onConsignorCreated()
            handleClose()
            resetForm()

            toast.success(response.data.message, {
                id: 'createConsignor',
                duration: 8000
            })
        } catch (e: any) {
            if (e.response?.data?.detail) {
                const validationError = e.response.data.detail[0];
                toast.error(
                    validationError.msg[0].toUpperCase() + validationError.msg.slice(1), {
                        id: 'createConsignor',
                        duration: 5000,
                    }
                )
            } else {
                toast.error(e.response.data.details.message, {
                    id: 'createConsignor',
                    duration: 5000
                })
            }
        } finally {
            setIsSubmitting(false)
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'border-0 pb-0'}>
                </Modal.Header>
                <Modal.Body className='pt-10'>
                    <h2 className='text-center mb-10 fw-bolder fs-1'>Create Consigner</h2>

                    <Form className='mt-10' onSubmit={createConsignor}>
                        <Form.Group className="mb-2 p-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control value={email}
                                          onChange={(event) => setEmail(event.target.value)}
                                          required={true}
                                          className='form-control-solid'
                                          type="email"
                                          placeholder="name@example.com"
                            />
                        </Form.Group>
                        <Form.Group className="mb-2 p-4" controlId="phoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control value={phoneNumber}
                                          onChange={(event) => setPhoneNumber(event.target.value)}
                                          required={true}
                                          className='form-control-solid'
                                          type="text"
                                          placeholder="123-456-7890"
                            />
                        </Form.Group>
                        <Row className="mb-2 p-4">
                            <Form.Group as={Col} controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control value={firstName}
                                              onChange={(event) => setFirstName(event.target.value)}
                                              required={true}
                                              className='form-control-solid'
                                              type="text"
                                              placeholder="John"
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control value={lastName}
                                              onChange={(event) => setLastName(event.target.value)}
                                              required={true}
                                              className='form-control-solid'
                                              type="text"
                                              placeholder="Doe"
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-2 p-4">
                            <Form.Group as={Col} controlId="tier">
                                <Form.Label>Tier</Form.Label>
                                <Form.Select
                                    className='form-select form-select-solid'
                                    placeholder='Select a tier...'
                                    required={true}
                                    onChange={(event) => setTierId(event.target.value)}
                                >
                                    <option value="">Select tier...</option>
                                    {tiers.map(t => (
                                        <option value={t.id}>{t.name} - {t.percent}%</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>

                        <Form.Group className="px-4">
                            <Button variant="primary" className='w-100 max-w-100 mt-10 mb-10' type='submit'>
                            {isSubmitting ? 'Creating...' : 'Submit'}
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CreateConsignorModal
